import moment from 'moment'
import { TResultSummary } from './api'

export type TSwarmSeries = {
  name: string
  data: TSwarmPoint[]
}

export type TSwarmPoint = {
  x: string
  y: number[]
  status?: string
  hits?: number
}

export const buckets = [
  {
    id: 'pass',
    name: 'Pass'
  },
  {
    id: 'error',
    name: 'Error'
  },
  {
    id: 'alert',
    name: 'Alert'
  }
]

export function getBlankTimeSeries(length: number = 72) {
  const seedArray = Array.from(Array(length).keys())
  return seedArray
    .map((_value, index) => {
      const time = moment()
        .minute(0)
        .second(0)
        .millisecond(0)
        .subtract(index, 'hours')
        .utc()
        .format()

      return {
        x: time,
        y: 0
      }
    })
    .reverse()
}

export function resultsToTimeline(resultsArray: TResultSummary[]) {
  const minTime = 0.25 * 60 * 60 * 1000 // 30 mins. To be visibly rendered, events need to be a sensible duration.
  const resultsByHost = new Map()
  const groupedResultsByStatus = new Map()

  resultsArray.forEach((data) => {
    if (!data || !data['data.status']) return
    const end_time = data['data.end_time']
    const status = data['data.status']
    const hostname = data['header.hostname']
    const oldValue = resultsByHost.has(hostname)
      ? resultsByHost.get(hostname)
      : []

    resultsByHost.set(hostname, [
      ...oldValue,
      {
        x: hostname,
        y: [new Date(end_time).getTime(), new Date(end_time).getTime()],
        status
      }
    ] as TSwarmPoint[])
  })

  resultsByHost.forEach((data) => {
    let lastStatus: string | undefined = ''

    // Group / Reduce into continuos status timelines
    let groupedData = data.reduce(
      (accumulator: TSwarmPoint[][] | TSwarmPoint, dataItem: TSwarmPoint) => {
        const isFirst = !Array.isArray(accumulator)
        const newAccumulator = isFirst
          ? [[accumulator as TSwarmPoint]]
          : accumulator

        if (dataItem.status === lastStatus) {
          newAccumulator[newAccumulator.length - 1].push(dataItem)
        } else if (isFirst) {
          // do nothing
        } else {
          newAccumulator.push([dataItem])
        }

        lastStatus = dataItem.status

        return newAccumulator
      }
    )

    // Catch items that skip reducer
    if (!Array.isArray(groupedData)) groupedData = [[groupedData]]

    // Reduce into single events with start and end times
    const aggregatedData = groupedData.map((eventArray: any[]) => {
      const hits = eventArray.length

      const aggregatedEvents = eventArray.reduce((a, b) => {
        return {
          x: `${a.x}`,
          y: [
            a.y[0] < b.y[0] ? a.y[0] : b.y[0],
            a.y[1] > b.y[1] ? a.y[1] : b.y[1]
          ],
          status: a.status,
          hits
        } as TSwarmPoint
      })

      // check all items are min-length 30mins for visibility
      // return aggregatedEvents.map((event: TSwarmPoint) => {
      const [start, end] = aggregatedEvents.y

      if (start + minTime >= end) {
        return {
          ...aggregatedEvents,
          y: [start, start + minTime]
        }
      }
      return aggregatedEvents
      // })
    })

    // Set back onto map.
    aggregatedData.forEach(({ status, x, y }: TSwarmPoint) => {
      const oldResults = groupedResultsByStatus.has(status)
        ? groupedResultsByStatus.get(status)
        : []

      groupedResultsByStatus.set(status, [...oldResults, { x, y }])
    })
  })

  // Convert to Series
  const series = buckets.map(({ id, name }) => ({
    name,
    data: groupedResultsByStatus.get(id) || []
  }))

  return {
    series,
    hostCount: resultsByHost.size
  }

  // const newAggregatedSeries = resultsArray.reduce((accumulator, { data: { start_time, end_time }, header: { hostname } }) => {
  //   // const groupedData = new Map()

  //   // const hits = data.length

  //   // data.forEach((a) => {
  //   //   hosts.add(a.x)

  //   //   if (groupedData.has(a.x)) {
  //   //     const b = groupedData.get(a.x)

  //   //     groupedData.set(a.x, {
  //   //       x: `${a.x}`,
  //   //       y: [
  //   //         a.y[0] < b.y[0] ? a.y[0] : b.y[0],
  //   //         a.y[1] > b.y[1] ? a.y[1] : b.y[1]
  //   //       ]
  //   //     })
  //   //   } else {
  //   //     groupedData.set(a.x, a)
  //   //   }
  //   // })

  //   // return {
  //   //   name,
  //   //   data: Array.from(groupedData.values())
  //   // }
  // })

  // const aggregatedSeries = series.map(({ name, data }) => {
  //   const groupedData = new Map()

  //   const hits = data.length

  //   data.forEach((a) => {
  //     hosts.add(a.x)

  //     if (groupedData.has(a.x)) {
  //       const b = groupedData.get(a.x)

  //       groupedData.set(a.x, {
  //         x: `${a.x}`,
  //         y: [
  //           a.y[0] < b.y[0] ? a.y[0] : b.y[0],
  //           a.y[1] > b.y[1] ? a.y[1] : b.y[1]
  //         ]
  //       })
  //     } else {
  //       groupedData.set(a.x, a)
  //     }
  //   })

  //   return {
  //     name,
  //     data: Array.from(groupedData.values())
  //   }
  // })
}
