import React, { FC, MouseEventHandler, useContext } from 'react'
import { navigate, PageProps } from 'gatsby'
import {
  Box,
  ButtonGroup,
  CircularProgress,
  Grid,
  NoSsr,
  Typography,
  useTheme
} from '@mui/material'
import { Card, HeroStat, LoadableChart, Notice, Button } from '@components'
import {
  getStatus,
  getStatusTags,
  StatusResponse,
  StatusTagsResponse
} from '@utils/api'
import useApi, { TUseApiHook } from '@hooks/useApi'
import moment from 'moment'
import GenericPage from '@templates/Generic/Page'
import { mergeArrays } from '@utils/misc'
import { getBlankTimeSeries } from '@utils/charting'
import { titleCase } from 'title-case'
import {
  licenseExceededNotice,
  licenseExpiredNotice,
  licenseExpiringNotice,
  unlicensedNotice
} from '@components/Notice/presets'
import { NoticeProps } from '@components/Notice'
import { GrUpgrade } from 'react-icons/gr'
import { getUpgradeUrl } from '@utils/license'
import AuthContext from '@utils/contexts/AuthContext'
import useLicense from '@utils/hooks/useLicense'
import useFeatures from '@utils/hooks/useFeatures'
import { Add, Refresh } from '@mui/icons-material'
import { GridLinkOperator } from '@mui/x-data-grid-pro'
import Color from 'color'

const DashboardPage: FC<PageProps> = () => {
  const { loading, isLoggedIn } = useContext(AuthContext)
  const { license, isUnlicensed, isExpiring, hasExpired, hasExceededLimit } =
    useLicense()

  const api = useApi<StatusResponse>({
    apiMethod: getStatus
  })

  const { response } = api

  const tagApi = useApi<StatusTagsResponse>({
    apiMethod: getStatusTags,
    params: {
      result_status: 'alert'
    }
  })

  const getNoticeProps = (): NoticeProps | null => {
    if (!isLoggedIn || !license) return null
    if (hasExpired) return licenseExpiredNotice
    if (isExpiring) return licenseExpiringNotice
    if (isUnlicensed) return unlicensedNotice
    if (hasExceededLimit('hosts')) return licenseExceededNotice

    return null
  }

  const noticeProps = getNoticeProps()

  const handleRefresh: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    tagApi.refresh(
      {
        result_status: 'alert'
      },
      {
        showSuccessMessage: false
      }
    )
    api.refresh()
  }

  return (
    <GenericPage
      title="Dashboard"
      maxWidth="lg"
      notice={
        noticeProps && !loading ? (
          <Notice maxWidth="lg" {...noticeProps} />
        ) : null
      }
      actions={
        <ButtonGroup variant="outlined" aria-label="Host Actions">
          <Button endIcon={<Add />} to="/hosts/credentials/add/">
            Add Credential
          </Button>
          <Button
            feature="jump_hosts"
            endIcon={<Add />}
            to="/hosts/jump-hosts/add/"
          >
            Add Jump Host
          </Button>
          <Button endIcon={<Add />} to="/hosts/add/">
            Add Host
          </Button>
          <Button
            role="button"
            endIcon={<Refresh />}
            href="#"
            onClick={handleRefresh}
          >
            Refresh
          </Button>
        </ButtonGroup>
      }
    >
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs>
          <HeroStat
            loading={!response}
            heading="Total Alerts"
            headingButton={
              <Button
                variant="outlined"
                size="small"
                onClick={() =>
                  navigate('/results/', {
                    state: {
                      filter: {
                        items: [
                          {
                            columnField: 'results_alert',
                            operatorValue: '>',
                            value: 0
                          }
                        ],
                        linkOperator: 'and' as GridLinkOperator
                      }
                    }
                  })
                }
              >
                View
              </Button>
            }
            value={response?.results.hourly.alert.total || 0}
            unit={` Alerts`}
            isGood={
              (response && response?.results.hourly.alert.total < 1) || false
            }
            isBad={
              (response && response?.results.hourly.alert.total > 0) || false
            }
            description="Alerts detected by Sandfly."
          ></HeroStat>
        </Grid>

        <Grid item xs>
          <HeroStat
            loading={!response}
            heading="Total Errors"
            headingButton={
              <Button
                variant="outlined"
                size="small"
                onClick={() =>
                  navigate('/results/', {
                    state: {
                      filter: {
                        items: [
                          {
                            columnField: 'results_error',
                            operatorValue: '>',
                            value: 0
                          }
                        ],
                        linkOperator: 'and' as GridLinkOperator
                      }
                    }
                  })
                }
              >
                View
              </Button>
            }
            value={response?.results.hourly.error.total || 0}
            unit={` Errors`}
            description="Errors whilst scanning."
            isGood={
              (response && response?.results.hourly.error.total < 1) || false
            }
            isOfConcern={
              (response && response?.results.hourly.error.total > 0) || false
            }
          ></HeroStat>
        </Grid>

        <Grid item xs>
          <HeroStat
            loading={!response}
            heading="Total Passes"
            headingButton={
              <Button
                variant="outlined"
                size="small"
                onClick={() =>
                  navigate('/results/', {
                    state: {
                      filter: {
                        items: [
                          {
                            columnField: 'results_pass',
                            operatorValue: '>',
                            value: 0
                          }
                        ],
                        linkOperator: 'and' as GridLinkOperator
                      }
                    }
                  })
                }
              >
                View
              </Button>
            }
            value={response?.results.hourly.pass.total || 0}
            unit={` Passes`}
            description="Sandflies passed."
            isGood={true}
          ></HeroStat>
        </Grid>
        <Grid item xs>
          <HeroStat
            heading="Host Limit"
            headingButton={
              <Button variant="outlined" size="small" to="/hosts/">
                View
              </Button>
            }
            value={response?.hosts.total || 0}
            unit={` / ${license?.limits.hosts || '0'}`}
            description="Registered vs. license limit."
            isGood={
              (response &&
                license &&
                response?.hosts.total < license?.limits.hosts) ||
              true
            }
            isOfConcern={
              (response &&
                license &&
                response?.hosts.total >= license?.limits.hosts - 2) ||
              false
            }
            isBad={
              (response &&
                license &&
                response?.hosts.total > license?.limits.hosts) ||
              false
            }
          ></HeroStat>
        </Grid>
        <Grid item xs>
          <HeroStat
            heading="Active Hosts"
            headingButton={
              <Button variant="outlined" size="small" to="/hosts/">
                View
              </Button>
            }
            value={response?.hosts.total_active || 0}
            unit={` / ${response?.hosts.total || 0}`}
            description="Hosts active vs. registered."
            isGood={
              (response &&
                response.hosts.total_active === response.hosts.total) ||
              false
            }
            isOfConcern={
              (response &&
                response.hosts.total_active < response.hosts.total) ||
              false
            }
            isBad={
              (response &&
                response.hosts.total_active * 1.25 < response.hosts.total) ||
              false
            }
          ></HeroStat>
        </Grid>

        <Grid item xs={12}>
          <DashboardPageTags api={tagApi} />
        </Grid>

        <Grid item xs={12}>
          <DashboardPageActivity api={api} />
        </Grid>
      </Grid>
    </GenericPage>
  )
}

const DashboardPageTags: FC<{ api: TUseApiHook<StatusTagsResponse> }> = ({
  api
}) => {
  const theme = useTheme()
  const { canUseFeature } = useFeatures()
  const { response } = api
  const series: ApexAxisChartSeries = []
  const showOnly24hr = !canUseFeature('data_retention_max_hours')
  const blankSeries = getBlankTimeSeries(showOnly24hr ? 24 : 72)
  const tags = response ? Object.keys(response.tags) : []
  const goodColor = Color(theme.palette.primary.main)
  const badColor = Color(theme.palette.error.main)
  const bg = Color(theme.palette.background.paper)

  tags.forEach((tag) => {
    const data = response
      ? response.tags[tag].data.map(({ time, total }) => ({
          x: time,
          y: total
        }))
      : []

    series.push({
      name: titleCase(tag.replace('attack.tactic.', '').replace('.', ' ')),
      data: mergeArrays(blankSeries, data, 'x')
    })
  })

  return (
    <Card
      heading="Sandfly Alerts by Tags"
      feature="data_retention_max_hours"
      headingButton={
        <Button variant="outlined" size="small" to="/results/">
          View Results
        </Button>
      }
    >
      <Grid
        container
        alignItems="center"
        sx={{
          '& .apexcharts-heatmap-series': {
            '& rect[val]': {
              stroke: (theme) => theme.palette.background.paper
            }
          }
        }}
      >
        <NoSsr>
          {showOnly24hr && (
            <Grid item sm={5}>
              <Box p={4}>
                <Typography variant="h3">Get Improved Visibility</Typography>
                <Typography variant="body1">
                  Improve result retention and view results up to 72 hours by
                  upgrading to one of our paid plans.
                </Typography>
                <Box pt={2}>
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    startIcon={<GrUpgrade size={16} />}
                    onClick={() =>
                      window.open(
                        getUpgradeUrl({
                          componentName: 'Upgrade Heatmap Prompt',
                          feature: 'data_retention_max_hours'
                        }),
                        '_blank',
                        'noopener'
                      )
                    }
                  >
                    Upgrade now
                  </Button>
                </Box>
              </Box>
            </Grid>
          )}
          <Grid item sm={showOnly24hr ? 7 : 12}>
            <Box p={2} pt={0} width="100%">
              {api.loading ? (
                <Box
                  height={365}
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress
                    color="secondary"
                    style={{ display: 'flex' }}
                  />
                </Box>
              ) : (
                <LoadableChart
                  // className={classes.heatmap}
                  series={series}
                  type="heatmap"
                  height="350"
                  options={{
                    theme: {
                      mode: theme.palette.mode
                    },

                    chart: {
                      fontFamily: theme.typography.fontFamily,
                      background: 'transparent',
                      height: 50,
                      type: 'heatmap',
                      toolbar: {
                        show: false
                      },
                      animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 400,
                        animateGradually: {
                          enabled: false,
                          delay: 150
                        },
                        dynamicAnimation: {
                          enabled: true,
                          speed: 350
                        }
                      }
                    },
                    legend: {
                      show: false
                    },
                    grid: {
                      show: true,
                      borderColor: theme.palette.divider,
                      xaxis: {
                        lines: {
                          show: true
                        }
                      },
                      yaxis: {
                        lines: {
                          show: true
                        }
                      }
                    },
                    dataLabels: {
                      enabled: false
                    },
                    xaxis: {
                      type: 'datetime',
                      max: moment().utc().valueOf(),
                      min: moment()
                        .subtract(showOnly24hr ? 1 : 3, 'days')
                        .utc()
                        .valueOf(),
                      labels: {
                        formatter: function (val) {
                          const now = moment()
                          return `-${now.diff(moment(val), 'hours')}hrs`
                        }
                      }
                    },
                    yaxis: {
                      opposite: true,
                      labels: {
                        show: true,
                        align: 'left',
                        style: {
                          colors: [],
                          fontSize: '12px',
                          fontWeight: 400
                        }
                      }
                    },
                    plotOptions: {
                      heatmap: {
                        radius: 0,
                        enableShades: false,
                        useFillColorAsStroke: true,
                        colorScale: {
                          ranges: [
                            {
                              from: -1,
                              to: 0,
                              name: 'none',
                              color: goodColor.mix(bg, 0.85).hex()
                            },
                            {
                              from: 1,
                              to: 10,
                              name: 'none',
                              color: badColor.mix(bg, 0.5).hex()
                            },
                            {
                              from: 2,
                              to: 9999999,
                              name: 'Alerts',
                              color: badColor.hex()
                            }
                          ]
                        }
                      }
                    }
                  }}
                />
              )}
            </Box>
          </Grid>
        </NoSsr>
      </Grid>
    </Card>
  )
}

const DashboardPageActivity: FC<{ api: TUseApiHook<StatusResponse> }> = ({
  api: { loading, response }
}) => {
  const theme = useTheme()
  const blankSeries = getBlankTimeSeries()
  const goodColor = Color(theme.palette.primary.main)
  const badColor = Color(theme.palette.error.main)
  const bg = Color(theme.palette.background.paper)
  const { isTrial } = useLicense()

  const all =
    response?.results.hourly.all.data.map(({ time, total }) => ({
      x: time,
      y: total
    })) || []

  const alerts =
    response?.results.hourly.alert.data.map(({ time, total }) => ({
      x: time,
      y: total
    })) || []

  const chartSeries = [
    {
      name: 'All Results',
      type: 'line',
      data: mergeArrays(blankSeries, all, 'x')
    },
    {
      name: 'Alerts',
      type: 'line',

      data: mergeArrays(blankSeries, alerts, 'x')
    }
  ]

  return (
    <Card
      heading="Threat Detection Activity"
      feature="data_retention_max_hours"
      headingButton={
        <Button variant="outlined" size="small" to="/results/">
          View Results
        </Button>
      }
    >
      <Box p={2} pt={0} width="100%">
        {loading ? (
          <Box
            height={365}
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress color="secondary" style={{ display: 'flex' }} />
          </Box>
        ) : (
          <LoadableChart
            series={chartSeries}
            type="line"
            height="300"
            options={{
              colors: [goodColor.mix(bg, 0.6).hex(), badColor.hex()],
              theme: {
                mode: theme.palette.mode
              },
              chart: {
                type: 'line',
                toolbar: {
                  show: false
                },
                fontFamily: theme.typography.fontFamily,
                background: 'transparent',

                events: {
                  markerClick: function (
                    event,
                    chartContext,
                    { seriesIndex, dataPointIndex, config }
                  ) {
                    console.log(
                      event,
                      chartContext,
                      seriesIndex,
                      dataPointIndex,
                      config
                    )
                  }
                }
              },
              grid: {
                borderColor: theme.palette.divider,
                row: {
                  colors: [theme.palette.divider, 'transparent'], // takes an array which will be repeated on columns
                  opacity: 0.2
                }
              },
              dataLabels: {
                enabled: true,
                enabledOnSeries: [1],
                formatter: function (val: number): any {
                  if (val !== 0) {
                    return val
                  }
                }
              },

              legend: {
                position: 'top',
                horizontalAlign: 'left'
              },
              yaxis: [
                {
                  min: 1,
                  tickAmount: 10,
                  title: {
                    text: 'All Results'
                  }
                },
                {
                  opposite: true,
                  title: {
                    text: 'Alerts'
                  }
                }
              ],

              xaxis: {
                type: 'datetime',
                max: moment().utc().valueOf(),
                min: moment()
                  .subtract(isTrial ? 25 : 73, 'hours')
                  .utc()
                  .valueOf(),
                labels: {
                  formatter: function (val) {
                    const now = moment()
                    return `-${now.diff(moment(val), 'hours')}hrs`
                  }
                }
              },
              stroke: {
                curve: ['smooth', 'smooth'],
                width: [4, 2]
              }
            }}
          />
        )}
      </Box>
    </Card>
  )
}

export default DashboardPage
